import React, { useState } from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import "../layouts/Primary/styles/skin/templates/_blog.scss"
import BlogListing from "../components/blogs/listing"
// import ArrowRight from "../components/SVGs/arrow-right.inline.svg"
import BlogFeaturedPost from "../components/blogs/feature-post"
import BlogListingFilters from "../components/blogs/blog-listing-filters"
import { format, parse, toDate } from "date-fns"
import { intersection } from "lodash"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"

export interface IBlogDetailProps {
  allContentfulCareersBlogPost: {
    nodes: Array<{
      id: string
      category: string
      heading: string
      content: string
      date: string
      teams: string
      image: any
      author: {
        name: string
        biography: {
          biography: string
        }
        image: any
      }
      featured: boolean
      tags: Array<string>
    }>
    allCategories: Array<string>
    allTags?: Array<string>
    allTeams: Array<string>
  }
}

interface ICareersBlogDetailContext {
  id: string
  slug: string
  locale: string
}

const BlogDetail: React.FC<PageProps<IBlogDetailProps, ICareersBlogDetailContext>> = ({ pageContext, path, data }) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: "Blog listing",
    description: "",
    image: "",
  }
  const featuredBlog = data.allContentfulCareersBlogPost.nodes.find(blog => blog.featured)

  let filterDateValues = data.allContentfulCareersBlogPost.nodes.map(blog => {
    if (blog.date) {
      format(parse(blog.date, "yyyy-MM-dd", new Date()), "yyyy")
    }
  })
  filterDateValues = filterDateValues.filter((item, index) => filterDateValues.indexOf(item) === index)

  const filterBlogs = filters =>
    data.allContentfulCareersBlogPost.nodes.filter(blog => {
      if (featuredBlog && blog.id === featuredBlog.id) {
        return false
      }
      if (filters.category && blog.category !== filters.category) {
        return false
      }
      if (filters.team && blog.teams !== filters.team) {
        return false
      }
      if (filters.tags && filters.tags.length > 0) {
        if (intersection(blog.tags, filters.tags).length !== filters.tags.length) {
          return false
        }
      }
      return true
    })

  const [blogs, setBlogs] = useState(filterBlogs({}))

  const onFilter = filters => setBlogs(filterBlogs(filters))
  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substring(0, 2),
      }}
    >
      <Layout seoData={seoData} dispatch={() => false} className='blog-main-container'>
        <BlogFeaturedPost blog={featuredBlog} />
        <div className="container blog-container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <BlogListingFilters
                categories={data.allContentfulCareersBlogPost.allCategories}
                teams={data.allContentfulCareersBlogPost.allTeams}
                dates={filterDateValues}
                onFilter={onFilter}
              />
              <BlogListing blogs={blogs} showPagination />
            </div>
          </div>
        </div>
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default BlogDetail

export const query = graphql`
  query {
    allContentfulCareersBlogPost(filter: { node_locale: { eq: "en-US" } }, sort: { order: DESC, fields: date }) {
      allTeams: distinct(field: teams)
      allCategories: distinct(field: category)
      nodes {
        ...BlogPostFields
      }
    }
  }
`
