import React, { useEffect, useState } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import ModalVideo from "../../../elements/ModalVideo"
import BlogListingSingle from "../listing-single"
import Pagination from "../../../elements/Pagination"

interface BlogListingProps {
  blogs: Array<any>
  showPagination?: boolean
}

const BlogListing: React.FC<BlogListingProps> = ({ blogs, showPagination }) => {
  const chunk = (arr: Array<any>, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))

  const perPage = 4

  const [items, setItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  const [paginationLength, setPaginationLength] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [videoInfo, setVideoInfo] = useState({})

  const openModal = (videoData, e: any) => {
    e.preventDefault()
    setModalShow(true)
    setVideoInfo({ ...videoData })
  }

  const hideModal = () => {
    setModalShow(false)
  }
  // Query string filters
  const [keyword] = useQueryParam("keyword", StringParam)
  const [category] = useQueryParam("category", StringParam)

  useEffect(() => {
    setPage(0)
    updateItems()
  }, [category, keyword])

  useEffect(() => {
    updateItems()
  }, [blogs, page])

  const updateItems = () => {
    const chunkedJobs = chunk(blogs, perPage)

    setPageCount(items.length)
    setPaginationLength(chunkedJobs.length)
    setItems(chunkedJobs[page] || [])
  }

  const handlePageChange = (pageChange: number) => {
    if (pageChange < 0 || pageChange > paginationLength - 1) {
      return
    }
    setPage(pageChange)
  }

  return (
    <>
      <div className="blog-listing" id="blog-listing">
        {items.length > 0 ? (
          items.map((blog, index) => (
            <BlogListingSingle {...blog} openModal={openModal} key={blog.slug} index={index} />
          ))
        ) : (
          <p className="text-center h4">Sorry, no items match your criteria</p>
        )}
      </div>
      {showPagination && <Pagination paginationLength={paginationLength} page={page} onPageChange={handlePageChange} />}
      <ModalVideo show={modalShow} onHide={hideModal} {...videoInfo} />
    </>
  )
}

export default BlogListing
