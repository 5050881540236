import React, { useState } from "react"
import { Collapse } from "react-bootstrap"
import { isMobile } from "../../../utils/helpers"
import ArrowRightSVG from "../../SVGs/arrow-right.inline.svg"
import CloseSVG from "../../SVGs/close.inline.svg"
import CaretDown from "../../SVGs/caret-down.inline.svg"

interface BlogListingFilters {
  categories: Array<string>
  tags?: Array<string>
  teams?: Array<string>
  dates: Array<string>
  onFilter?: Function
}

const BlogListingFilters: React.FC<BlogListingFilters> = ({ categories, tags, teams, onFilter }) => {
  const [open, setOpen] = useState(false)
  const [collapseMobile, setCollapseMobile] = useState(false)

  const [filters, setFilters] = useState({ category: "", team: "", tags: [] })

  const handleTagsUpdate = (event, tag) => {
    event.preventDefault()
    const tagIndex = filters.tags.indexOf(tag)
    if (tagIndex > -1) {
      filters.tags.splice(tagIndex, 1)
      return handleFilterUpdate("tags", filters.tags)
    }
    return handleFilterUpdate("tags", [...filters.tags, tag])
  }

  const handleFilterUpdate = (type: string, value: any) => {
    const newFilters = { ...filters, [type]: value }
    setFilters(newFilters)
    if (!isMobile() && onFilter) {
      onFilter(newFilters)
    }
  }
  const handleMobileFilterUpdate = () => {
    if (onFilter) {
      onFilter(filters)
    }
    setCollapseMobile(!collapseMobile)
  }

  const tagLink = (tag: string) => (
    <a
      className={`btn btn-dark btn-sm${filters.tags.includes(tag) ? " active" : ""}`}
      href="#"
      onClick={e => handleTagsUpdate(e, tag)}
      key={`tag-${tag}`}
    >
      {tag}
    </a>
  )

  return (
    <div className="blog-filters">
      <button
        className="btn btn-outline-light btn-block justify-content-center d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#blog-filters"
        aria-expanded={collapseMobile}
        onClick={() => setCollapseMobile(!collapseMobile)}
        aria-controls="blog-filters"
      >
        Media and Teams
      </button>
      <Collapse className="collapse d-lg-block" id="blog-filters" in={collapseMobile}>
        <div className="collapse d-lg-block">
          <div className="text-right d-lg-none">
            <button
              className="btn btn-close"
              type="button"
              data-toggle="collapse"
              data-target="#blog-filters"
              aria-expanded="false"
              aria-controls="blog-filters"
              onClick={() => setCollapseMobile(!collapseMobile)}
            >
              <CloseSVG className="icon-sprite" />
              <span className="sr-only">Close blog filters</span>
            </button>
          </div>
          <div className="row">
            <div className="form-group col-lg-6 col-xl-6">
              <label htmlFor="blog-category" className="sr-only">
                {" "}
                Blog Categories <span className="required">*</span>
              </label>
              <select
                name="blog-category"
                id="blog-category"
                className="form-control"
                required
                value={filters.category}
                onChange={e => handleFilterUpdate("category", e.currentTarget.value)}
              >
                <option value="">All Media</option>
                {categories.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-6 col-xl-6">
              <label htmlFor="blog-date" className="sr-only">
                {" "}
                Blog Date <span className="required">*</span>
              </label>
              <select
                name="blog-date"
                id="blog-date"
                className="form-control"
                required
                value={filters.team}
                onChange={e => handleFilterUpdate("team", e.currentTarget.value)}
              >
                <option value="">Team</option>
                {teams &&
                  teams.map((teamFilter, i) => (
                    <option key={i} value={teamFilter}>
                      {teamFilter}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-12 col-xl-8">
              <div className="blog-tags">
                {tags && tags.length > 0 && tags.slice(0, 10).map(tag => tagLink(tag))}
                {tags && tags.length > 10 && (
                  <button
                    onClick={() => setOpen(!open)}
                    className="btn btn-sm"
                    type="button"
                    data-toggle="collapse"
                    data-target="#blog-tags-all"
                    aria-expanded={open}
                    aria-controls="blog-tags-all"
                  >
                    See all {tags.length} tags <CaretDown className={"icon-sprite"} />
                  </button>
                )}
              </div>
              {tags && tags.length > 10 && (
                <Collapse in={open} appear>
                  <div className="blog-tags-all" id="blog-tags-all">
                    {tags.slice(10).map(tag => tagLink(tag))}
                  </div>
                </Collapse>
              )}
            </div>
            <div className="col-12 mt-4 d-lg-none">
              <button className="btn btn-light has-addon" type="button" onClick={() => handleMobileFilterUpdate()}>
                Filter Posts
                <span className="btn-addon bg-primary">
                  <ArrowRightSVG className="icon-sprite" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default BlogListingFilters
