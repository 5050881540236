import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ContentfulLink from "../../../elements/ContentfulLink"
import BlogDate from "../blog-date"
import PlaySVG from "../../SVGs/play.inline.svg"
import classNames from "classnames"

interface BlogListingSingleProps {
  slug: string
  heading: string
  category: string
  date: string
  image: any
  content: any
  openModal: any
  index: number
}

const BlogListingSingle: React.FC<BlogListingSingleProps> = ({
  slug,
  date,
  image,
  heading,
  category,
  content,
  openModal,
  index,
}) => {
  let videoData = {}
  const videoRef =
    category === "Video"
      ? content?.references?.find(
          ref =>
            ref["__typename"] === "ContentfulBrightcoveVideo" ||
            ref["__typename"] === "ContentfulComponentPodcast" ||
            ref.brightcoveVideoId
        )
      : null

  if (videoRef) {
    videoData.video =
      videoRef["__typename"] === "ContentfulBrightcoveVideo" || videoRef?.brightcoveVideoId
        ? videoRef.brightcoveVideoId
        : videoRef.content?.content
    videoData.podcast = videoRef["__typename"] === "ContentfulComponentPodcast" ? true : false
  }

  return (
    <article className={classNames("card card-blog-summary", { "card-blog-summary-border": index !== 0 })}>
      <div className="row flex-row-reverse">
        <div className="col-lg-5">
          <div className="card-image">
            <ContentfulLink
              className="btn-blog"
              link={{
                type: "Page",
                page: { slug: slug },
                label: "",
              }}
            >
              <GatsbyImage
                className="img-fluid"
                image={image.fluid}
                alt={image.title || ""}
                imgStyle={{ height: "82.5%" }}
              />
            </ContentfulLink>
            {category === "Video" && videoData.video ? (
              <a className="js-video" href="#" onClick={e => openModal(videoData, e)}>
                <span className="play-icon">
                  <PlaySVG className="icon-sprite" />
                  <div className="sr-only">Play</div>
                </span>
              </a>
            ) : null}
            <BlogDate>{date}</BlogDate>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="card-body">
            <p className="card-subtitle">{category}</p>
            <ContentfulLink
              className="btn-blog"
              link={{
                type: "Page",
                page: { slug: slug },
                label: "",
              }}
            >
              <h2 className="card-title">{heading}</h2>
            </ContentfulLink>
            <ContentfulLink
              className="btn-link card-listing-single-link"
              link={{
                type: "Page",
                page: { slug: slug },
                label: category === "Video" ? "Learn More" : "Read More",
              }}
            />
          </div>
        </div>
      </div>
    </article>
  )
}

export default BlogListingSingle
