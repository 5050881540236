import React from "react"
import CTA from "../../../elements/CTA"
import { GatsbyImage } from "gatsby-plugin-image"
import BlogDate from "../blog-date"
import ContentfulLink from "../../../elements/ContentfulLink"

interface BlogFeaturedPostProps {
  blog: {
    id: string
    category: string
    slug: string
    heading: string
    date: string
    featuredImage: any
  }
}

const BlogFeaturedPost: React.FC<BlogFeaturedPostProps> = ({ blog }) => {
  return (
    <div className="featured-post">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-6 featured-post-image">
            {blog.featuredImage && (
              <ContentfulLink
                className="btn-blog"
                link={{
                  type: "Page",
                  page: { slug: blog?.slug },
                  label: "",
                }}
              >
                <GatsbyImage
                  imgStyle={{ height: "82.5%" }}
                  className="img-fluid"
                  image={blog.featuredImage.fluid}
                  alt={blog.featuredImage.title || ""}
                />
              </ContentfulLink>
            )}
          </div>
          <div className="col-lg-6">
            {blog && (
              <>
                <p className="h4">
                  {blog.category}
                  <BlogDate>{blog.date}</BlogDate>
                </p>
                <ContentfulLink
                  className="btn-blog"
                  link={{
                    type: "Page",
                    page: { slug: blog?.slug },
                    label: "",
                  }}
                >
                  <h1>
                    <span>{blog.heading}</span>
                  </h1>
                </ContentfulLink>
                <CTA type="Page" label="Read More" page={{ slug: blog.slug }} buttonClass='featured-post-button-span'/>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogFeaturedPost
